import * as Sentry from "@sentry/react";
import type { Router } from "@tanstack/react-router";
import { LocalStorage } from "./local-storage";

type SentryInitOptions = {
  enabled: boolean;
  router: Router<any, any, any, any>;
};

export const initSentry = (options: SentryInitOptions) => {
  if (!options.enabled) {
    // Sentry has an `enabled` flag, but we prefer running with
    // no overhead, instead of only not reporting.
    return;
  }

  Sentry.init({
    release: __RELEASE_NAME__,
    environment: import.meta.env.MODE,
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        unblock: [".sentry-unblock, [data-sentry-unblock]"],
        unmask: [".sentry-unmask, [data-sentry-unmask]"],
      }),
      Sentry.captureConsoleIntegration({
        levels: ["warn", "error"],
      }),
      Sentry.tanstackRouterBrowserTracingIntegration(options.router),
    ],
    // Tracing
    tracesSampleRate: 1.0,
    tracePropagationTargets: [
      "localhost", // For local development
      "https://product-api.golassie.com", // For the production server
    ],
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,
  });

  // scrape localStorage data
  try {
    const lastSyncedAt = LocalStorage.get("lastSyncedAt");
    const selectedPractice = LocalStorage.get("selectedPractice");
    const version = LocalStorage.get("version");
    const clientId = LocalStorage.get("clientId");
    const selectedPracticeMetadata = LocalStorage.get(
      "selectedPracticeMetadata",
    );

    Sentry.setContext("cachedStorage", {
      lastSyncedAt,
      selectedPractice,
      version,
      clientId,
      selectedPracticeMetadata,
    });
  } catch (e) {
    Sentry.addBreadcrumb({
      category: "sentry-init",
      message: "Error scraping localStorage",
      level: "error",
    });
  }
};
